import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { UsersService } from '@api/services/user.service';
import { StartupService } from '@core';
// tslint:disable-next-line: import-blacklist
import { ReuseTabService } from '@delon/abc';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ModalHelper } from '@delon/theme';
import { BaseComponent } from '@geekymedic/common';
import { PrivacyAgreementComponent } from '../privacy-agreement/privacy-agreement.component';
import { ServiceAgreementComponent } from '../service-agreement/service-agreement.component';

type NewType = ITokenService;

@Component({
  selector: 'app-wechat-login',
  templateUrl: './wechat-login.component.html',
  styleUrls: ['./wechat-login.component.less'],
})
export class WechatLoginComponent extends BaseComponent implements OnInit {
  constructor(
    injector: Injector,
    private modalHlp: ModalHelper,
    private userService: UsersService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: NewType,
    private startupSrv: StartupService,
  ) {
    super(injector);
  }

  checked = true;
  ngOnInit() {
    const url = `${location.protocol}//${location.host}/passport/wechat-login`;
    WxLogin({
      id: 'login_container',
      appid: 'wx8cc5aa2b7058ce35',
      scope: 'snsapi_login',
      redirect_uri: encodeURIComponent(url),
      state: '',
      style: '',
      href: '',
    });
    this.updateSingleChecked();
    this.routerInfo.queryParams.subscribe((data) => {
      if (data.code) {
        // todo 将微信扫码的code传给后端
        this.userService.wechatLogin({ WebchatCode: data.code }).subscribe((res) => {
          if (res.Isbind) {
            // 清空路由复用信息
            this.reuseTabService.clear();
            const isOk = localStorage.getItem('isOk');
            console.log(isOk + '<==isOk');
            if (isOk === '1') {
              // 设置用户Token信息
              this.tokenService.set({ token: res.Token, id: res.Store });
              // 存储user到缓存中
              localStorage.setItem('userInfo', JSON.stringify(res));
              //
              this.getUser();
            } else {
              this.checked = false;
              this.message.info('请同意协议');
            }
          } else {
            console.log('bind-mobile');
            this.router.navigate(['/passport/bind-mobile'], { queryParams: { WebchatCode: data.code } });
          }
        });
      }
    });
  }

  getUser() {
    this.userService.getInfo().subscribe((res) => {
      localStorage.setItem('user', JSON.stringify(res));
      // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
      this.startupSrv.load().then(() => {
        // tslint:disable-next-line: no-non-null-assertion
        let url = this.tokenService.referrer!.url || '/';
        if (url.includes('/passport')) {
          url = '/';
        }
        this.router.navigateByUrl(url);
      });
    });
  }
  /**
   * 服务协议
   */
  goServiceAgreement() {
    this.modalHlp.static(ServiceAgreementComponent).subscribe(() => {});
  }

  /**
   * 隐私协议
   */
  goPrivacyAreement() {
    this.modalHlp.static(PrivacyAgreementComponent).subscribe(() => {});
  }

  updateSingleChecked() {
    localStorage.setItem('isOk', this.checked ? '1' : '2');
  }
}
