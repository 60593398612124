import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _HttpClient } from '@delon/theme';
import { NzMessageService, UploadFile, UploadXHRArgs } from 'ng-zorro-antd';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, Subscriber } from 'rxjs';
import { FileCheckService } from '../../services/file-check.service';
import { UploadImageService } from './upload-img.service';

@Component({
  selector: 'app-upload-img',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadImgComponent),
      multi: true,
    },
  ],
  templateUrl: './upload-img.component.html',
  styleUrls: ['./upload-img.component.less'],
})
export class UploadImgComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  constructor(public uploadImageService: UploadImageService, private msg: NzMessageService, private fileCheckService: FileCheckService) {}

  @Input() imageIsCut = true; // 是否启用剪裁
  @Input() imageMaxLength = 1; // 最大上传个数
  @Input() imageRatio = 1 / 1; // 图片比例
  @Input() imageOutputWidth = 600; // 图片宽度
  @Input() imageFormat = 'jpeg'; // 图片格式
  @Input() imageName = ''; // 图片名
  @Input() imageLimitType = ['jpeg', 'png', 'jpg']; // 允许上传的图片格式
  @Input() imageLimitSize = 1; // 限制上传的大小，单位兆，默认1m
  @Input() imageLimitDimension: [number, number] = null; // 限制上传像素大小,单位px,默认不限制
  @Input() isPay = false; // 是否支付，如果是则修改阿里oss的bucket配置，默认为否
  @Input() imageSize = 'small'; // 图片样式大小，默认值small,中等normal,大big
  @Input() showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true,
  }; // 上传配置

  private imageList: Array<string> = []; // 初始化的图片列表
  resetImglist = []; // 重置后图片列表
  imageFile: any = ''; // 1.4.1版本是‘imageFileChanged’
  croppedImage: any = '';
  croppedImageBase64: any = '';

  previewImage: string | undefined = '';
  previewVisible = false;

  isVisible = false;
  isConfirmLoading = false;
  subscriber: Subscriber<boolean>;
  loading = false; // 遮罩层

  imageChangedEvent: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  onChange: (value) => void = () => null;

  ngOnInit() {
    // 如果ie浏览器不给裁剪
    console.log(this.isIE());
    if (this.isIE()) {
      this.imageIsCut = false;
    }
  }

  ngOnDestroy(): void {
    // this.subject.unsubscribe();
  }
  /**
   * @description: 自定义上传图片
   * @params: {}
   * @return:
   */
  customCreateReq = (item: UploadXHRArgs) => {
    this.loading = true;
    // 如果是支付图片则改变bucket
    this.isPay ? this.uploadImageService.setPayBucket() : this.uploadImageService.setDefaultBucket();
    const file = this.imageIsCut ? this.croppedImage : item.file;
    return this.uploadImageService.upload(file).subscribe({
      next: (event: any) => {
        if (event.res.status === 200) {
          item.onSuccess!(event.res, file!, event);
          const img = this.resetImglist.find((l) => l.uid === item.file.uid);
          img.thumbUrl = this.isPay ? this.uploadImageService.getEncryptionImg(event.name) : event.res.requestUrls[0];
          this.resetImglist = [...this.resetImglist];
          this.imageList = this.resetImglist.map((i) => i.thumbUrl);
          this.onChange(this.imageList);
          this.msg.success('上传成功');
        } else {
          item.onError!(event.res, file!);
          this.msg.warning('上传失败，请重试');
          this.resetImglist = [];
        }
        this.isVisible = false;
        this.loading = false;
      },
      error: (error) => {
        this.msg.warning('上传失败，请重试');
        this.resetImglist = [];
        this.isVisible = false;
        this.loading = false;
      },
      complete: () => {
        this.isVisible = false;
        this.loading = false;
      },
    });
  }

  /**
   * @description: 每次剪切图像时都会发出ImageCroppedEvent
   * @params: {}
   * @return:
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBase64 = event.base64;
    // this.croppedImage = new Blob(this.croppedImageBase64);

    // this.croppedImage = new Blob([JSON.stringify(this.croppedImageBase64)], { type: 'text/plain' });
    this.croppedImage = this.blobToFile(this.croppedImageBase64);
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  /**
   * @description: 保存裁剪后图片
   * @params: {}
   * @return:
   */
  comfirImg() {
    this.subscriber.next(true);
    this.subscriber.complete();
  }

  /**
   * @description: 上传图片之前判断是否裁剪，如果裁剪则调用裁剪弹窗
   * @params: {file:File 图片文件}
   * @return:
   */
  beforeUpload = (file: File) => {
    return new Observable((subscriber) => {
      const type = this.imageLimitType && this.imageLimitType.length > 0 ? this.imageLimitType.map((l) => `image/${l}`) : false;
      this.fileCheckService
        .constraint(file, type, Number(this.imageLimitSize) * 1024 * 1024, this.imageLimitDimension)
        .subscribe((flag) => {
          if (flag) {
            // 如果剪裁则调用剪裁弹窗
            if (this.imageIsCut) {
              this.isVisible = true;
              this.imageFile = file;
              this.croppedImageBase64 = '';
              this.subscriber = subscriber;
            } else {
              subscriber.next(true);
              subscriber.complete();
            }
          } else {
            subscriber.next(false);
            subscriber.complete();
          }
        });
    });
  }

  /**
   * @description: 图片预览
   * @parms: {file: UploadFile 图片文件}
   * @return:
   */
  handlePreview = (file: UploadFile) => {
    // this.previewImage = file.response && file.response.requestUrls[0];
    this.previewImage = file.thumbUrl;
    this.previewVisible = true;
  }

  /**
   * @description: 图片移除
   * @parms: {file: UploadFile 图片文件}
   * @return:
   */
  handleRemove = (file: UploadFile) => {
    const url = file.thumbUrl;
    if (url) {
      this.imageList = this.imageList.filter((i) => i !== url);
      this.resetImages();

      this.onChange(this.imageList);
      // const path = url
      //   .split('/')
      //   .slice(3)
      //   .join('/');
      // return this.uploadImageService.delete(path);
    } else {
      return true;
    }
  }
  /**
   * @description: 上传图片改变
   * @paams: {}
   * @return:
   */
  handleChange(info: { file: UploadFile }): void {
    const status = info.file.status;
    if (status === 'done') {
    } else if (status === 'removed') {
    } else if (status === 'error') {
    }
  }

  /**
   * @description: 取消裁剪弹窗
   * @params: {}
   * @return:
   */
  handleCancel(): void {
    this.isVisible = false;
    this.subscriber.next(false);
    this.subscriber.complete();
  }

  /**
   * @description: 监听传入值改变
   * @params: {}
   * @return:
   */
  ngOnChanges() {}

  /**
   * @description: 传入值改变时重置图片
   * @params: {}
   * @return:
   */
  resetImages() {
    this.resetImglist = this.imageList.map((item, index) => {
      return { thumbUrl: item, uid: index };
    });
  }
  writeValue(obj: any): void {
    this.imageList = Array.isArray(obj) ? obj : obj ? [obj] : [];
    this.onChange(this.imageList);
    this.resetImages();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  blobToFile(dataurl) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], '', { type: mime });
  }

  // 是否ie浏览器
  isIE() {
    if ('ActiveXObject' in window) {
      return true;
    } else {
      return false;
    }
  }
}
