/*
 * @Author: your name
 * @Date: 2020-05-27 15:00:53
 * @LastEditTime: 2020-05-27 15:03:01
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/directives/input-space.directive.ts
 */

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup, FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[input-noSpace]',
})
export class InputNoSpaceDirective {
  constructor(private elementRef: ElementRef, private control: NgControl) {}

  @HostListener('keydown', ['$event'])
  keydownFun(evt) {
    if (evt.key.trim() == '') {
      evt.preventDefault();
    }
  }

  @HostListener('keyup', ['$event', '$event.target'])
  keyupFun(evt, target) {
    if (target.value) {
      this.control.control.setValue(target.value.replace(/(\s*)/g, ''));
    }
  }
}
