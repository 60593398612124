/*
 * @Description: 表单特殊字段校验
 * @Author: zhurunlan
 * @Contact: runlan.zhu@geekymedic.cn
 * @Date: 2019-07-15 16:36:07
 * @LastEditTime: 2020-05-27 14:33:59
 * @LastEditors: Please set LastEditors
 */

export class Validate {
  /**
   * 是否为手机号
   * @param value 手机号码
   */
  public static isMobile(value: string): boolean {
    return /^[1][3,4,5,7,8][0-9]{9}$/.test(value);
  }
  /**
   * 是否银行卡号
   * @param value 银行卡号
   */
  public static isBankCard(value: string): boolean {
    const len = value && value.length;
    if (len < 16 || len > 19) {
      return false;
    }
    const lastNum = value.substr(value.length - 1, 1); // 取出最后一位（与luhn进行比较）
    const first15Num = value.substr(0, value.length - 1); // 前15或18位
    const newArr = new Array();
    for (let i = first15Num.length - 1; i > -1; i--) {
      // 前15或18位倒序存进数组
      newArr.push(first15Num.substr(i, 1));
    }
    const arrJiShu = new Array(); // 奇数位*2的积 <9
    const arrJiShu2 = new Array(); // 奇数位*2的积 >9
    const arrOuShu = new Array(); // 偶数位数组
    for (let j = 0; j < newArr.length; j++) {
      if ((j + 1) % 2 == 1) {
        // 奇数位
        if (parseInt(newArr[j]) * 2 < 9) {
          arrJiShu.push(parseInt(newArr[j]) * 2);
        } else {
          arrJiShu2.push(parseInt(newArr[j]) * 2);
        }
      } else {
        arrOuShu.push(newArr[j]);
      }
    }

    const jishu_child1 = new Array(); // 奇数位*2 >9 的分割之后的数组个位数
    const jishu_child2 = new Array(); // 奇数位*2 >9 的分割之后的数组十位数
    for (let h = 0; h < arrJiShu2.length; h++) {
      jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
      jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
    }

    let sumJiShu = 0; // 奇数位*2 < 9 的数组之和
    let sumOuShu = 0; // 偶数位数组之和
    let sumJiShuChild1 = 0; // 奇数位*2 >9 的分割之后的数组个位数之和
    let sumJiShuChild2 = 0; // 奇数位*2 >9 的分割之后的数组十位数之和
    let sumTotal = 0;
    for (let m = 0; m < arrJiShu.length; m++) {
      sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
    }

    for (let n = 0; n < arrOuShu.length; n++) {
      sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
    }

    for (let p = 0; p < jishu_child1.length; p++) {
      sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
      sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
    }
    // 计算总和
    sumTotal = sumJiShu + sumOuShu + sumJiShuChild1 + sumJiShuChild2;

    // 计算luhn值
    const k = sumTotal % 10 == 0 ? 10 : sumTotal % 10;
    const luhn = 10 - k;

    return lastNum === luhn.toString();
  }
  /**
   * 是否身份证号
   * @param value 身份证号码
   */
  public static isIdNum(value: string): boolean {
    value = value && value.toUpperCase(); // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
    if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(value)) {
      // alert('输入的身份证号长度不对，或者号码不符合规定！\n15位号码应全为数字，18位号码末位可以为数字或X。');
      // alert('身份证号长度不正确或不符合规定！');
      return false;
    }
    // 验证前2位，城市符合
    const aCity = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江 ',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      71: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外',
    };
    if (aCity[parseInt(value.substr(0, 2))] == null) {
      // alert('身份证号不正确或不符合规定！');
      return false;
    }
    // alert('城市:'+aCity[parseInt(num.substr(0,2))]);

    // 下面分别分析出生日期和校验位
    let len, re;
    len = value.length;
    if (len == 15) {
      re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
      const arrSplit = value.match(re); // 检查生日日期是否正确
      const dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
      let bGoodDay;
      bGoodDay =
        dtmBirth.getFullYear() == Number(arrSplit[2]) &&
        dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
        dtmBirth.getDate() == Number(arrSplit[4]);
      if (!bGoodDay) {
        // alert('身份证号的出生日期不对！');
        return false;
      } else {
        // 将15位身份证转成18位 //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
        const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
        let nTemp = 0,
          i;
        value = value.substr(0, 6) + '19' + value.substr(6, value.length - 6);
        for (i = 0; i < 17; i++) {
          nTemp += Number(value.substr(i, 1)) * arrInt[i];
        }
        value += arrCh[nTemp % 11];
        return true;
      }
    }
    if (len == 18) {
      re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
      const arrSplit = value.match(re); // 检查生日日期是否正确
      const dtmBirth = new Date(arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
      let bGoodDay;
      bGoodDay =
        dtmBirth.getFullYear() == Number(arrSplit[2]) &&
        dtmBirth.getMonth() + 1 == Number(arrSplit[3]) &&
        dtmBirth.getDate() == Number(arrSplit[4]);
      if (!bGoodDay) {
        // alert(dtmBirth.getYear());
        // alert(arrSplit[2]);
        // alert('身份证号的出生日期不对！');
        return false;
      } else {
        // 检验18位身份证的校验码是否正确。 //校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
        let valnum;
        const arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
        const arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
        let nTemp = 0,
          i;
        for (i = 0; i < 17; i++) {
          nTemp += Number(value.substr(i, 1)) * arrInt[i];
        }
        valnum = arrCh[nTemp % 11];
        if (valnum != value.substr(17, 1)) {
          // alert('18位身份证的校验码不正确！应该为：' + valnum);
          // alert('18位身份证号的校验码不正确！');
          return false;
        }
        return true;
      }
    }
    return false;
  }
  /**
   * 是否url
   * @param url url
   */
  public static isUrl(url: string): boolean {
    return /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/.test(
      url,
    );
  }
  /**
   * 是否营业执照
   * @param value 营业执照
   */
  public static isBusinessLicense(value: string): boolean {
    return /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(value);
  }

  /**
   * 是否邮箱
   * @param value 邮箱
   */
  public static isEmail(value: string): boolean {
    return /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value);
  }
  /**
   * 是否为空
   * @param value
   */
  public static isEmpty(value: string): boolean {
    return /\S/.test(value);
  }
}
