/*
 * @Author: your name
 * @Date: 2020-05-18 10:21:49
 * @LastEditTime: 2020-05-27 12:12:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/routes/routes-routing.module.ts
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SimpleGuard } from '@delon/auth';
import { environment } from '@env/environment';
import { SelectivePreloadingStrategyService } from '@geekymedic/core';
// layout
import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutFullScreenComponent } from '../layout/fullscreen/fullscreen.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
// single pages
import { CallbackComponent } from './callback/callback.component';
// dashboard pages
import { DashboardComponent } from './dashboard/dashboard.component';
import { BindMobileComponent } from './passport/bind-mobile/bind-mobile.component';
// passport pages
import { MobileLoginComponent } from './passport/mobile-login/mobile-login.component';
import { WechatLoginComponent } from './passport/wechat-login/wechat-login.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [SimpleGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, data: { title: '首页' } },
      { path: 'exception', loadChildren: () => import('./exception/exception.module').then((m) => m.ExceptionModule) },
      { path: 'product-manage', loadChildren: () => import('./product-manage/product-manage.module').then((m) => m.ProductManageModule) },
      {
        path: 'system-setting-manage',
        loadChildren: () => import('./system-setting-manage/system-setting-manage.module').then((m) => m.SystemSettingManageModule),
      },
      {
        path: 'data-management',
        loadChildren: () => import('./data-management/data-management.module').then((m) => m.DataManagementModule),
      },
      {
        path: 'activity-management',
        loadChildren: () => import('./activity-management/activity-management.module').then((m) => m.ActivityManagementModule),
      },
    ],
  },
  // 全屏布局
  // {
  //     path: 'fullscreen',
  //     component: LayoutFullScreenComponent,
  //     children: [
  //     ]
  // },
  // passport
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      { path: 'wechat-login', component: WechatLoginComponent, data: { title: '微信登录' } },
      { path: 'mobile-login', component: MobileLoginComponent, data: { title: '手机号登录' } },
      { path: 'bind-mobile', component: BindMobileComponent, data: { title: '绑定手机号' } },
    ],
  },
  // 单页不包裹Layout
  { path: 'callback/:type', component: CallbackComponent },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
      // Pls refer to https://ng-alain.com/components/reuse-tab
      scrollPositionRestoration: 'top',
      preloadingStrategy: SelectivePreloadingStrategyService,
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule {}
