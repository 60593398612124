<div>
  <div class="wechat-accept">微信授权完成，请绑定手机号</div>
  <form nz-form [formGroup]="form" (ngSubmit)="submit()" role="form">
    <nz-alert *ngIf="error" [nzType]="'error'" [nzMessage]="error" [nzShowIcon]="true" class="mb-lg"></nz-alert>
    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="Tel">手机号</nz-form-label>
      <nz-form-control [nzSm]="20" [nzXs]="24" [nzErrorTip]="'手机号格式不正确'">
        <nz-input-group nzSize="large" nzPrefixIcon="user">
          <input class="inp" id="Tel" nz-input formControlName="Tel" placeholder="输入手机号" maxlength="11" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="Vcode">验证码</nz-form-label>
      <nz-form-control [nzSm]="20" [nzXs]="24" nzErrorTip="请输入验证码">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzSpan]="14">
            <nz-input-group nzSize="large" nzPrefixIcon="message">
              <input class="inp" nz-input nzSize="large" formControlName="Vcode" id="Vcode" placeholder="短信验证码"
                maxlength="6" appFilterChar='whitespace'>
            </nz-input-group>
          </div>
          <div nz-col [nzSpan]="10">
            <button type="button" nz-button nzSize="large" (click)="getCaptcha()" [disabled]="count" nzBlock>
              {{ count ? count + 's' : '获取验证码'}}
            </button>
          </div>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzSm]="20" [nzXs]="24" nzOffset="4">
        <button nz-button type="submit" nzType="primary" nzSize="large" nzBlock>登录</button>
      </nz-form-control>
    </nz-form-item>
  </form>
  <div>
    <label nz-checkbox [(ngModel)]="checked">确定即代表您已同意</label>
    <a class="register">《药极订服务协议》</a>和
    <a class="register">《药极订隐私协议》</a>
  </div>
</div>
<nz-modal [(nzVisible)]="isBindVisible" nzTitle="提示" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <p>手机号已被绑定过，是否确定解绑？</p>
</nz-modal>
