/*
 * @Author: your name
 * @Date: 2020-05-22 18:04:43
 * @LastEditTime: 2020-05-23 12:13:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/api/services/product.service.ts
 */

import { Injectable, Injector } from '@angular/core';
import { AddGoodsRequest } from '@api/models/product/AddGoodsRequest';
import { AddGoodsResponse } from '@api/models/product/AddGoodsResponse';
import { ChangeGoodsStateRequest } from '@api/models/product/ChangeGoodsStateRequest';
import { ChangeGoodsStateResponse } from '@api/models/product/ChangeGoodsStateResponse';
import { GetGoodsByBarCodeRequest } from '@api/models/product/GetGoodsByBarCodeRequest';
import { GetGoodsByBarCodeResponse } from '@api/models/product/GetGoodsByBarCodeResponse';
import { GetGoodsClassificationRequest } from '@api/models/product/GetGoodsClassificationRequest';
import { GetGoodsClassificationResponse } from '@api/models/product/GetGoodsClassificationResponse';
import { GetGoodsDetailRequest } from '@api/models/product/GetGoodsDetailRequest';
import { GetGoodsDetailResponse } from '@api/models/product/GetGoodsDetailResponse';
import { GetGoodsListRequest } from '@api/models/product/GetGoodsListRequest';
import { GetGoodsListResponse } from '@api/models/product/GetGoodsListResponse';
import { ModifyGoodsRequest } from '@api/models/product/ModifyGoodsRequest';
import { ModifyGoodsResponse } from '@api/models/product/ModifyGoodsResponse';
import { BaseService } from '@geekymedic/common';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }
  /**
   * 获取商品列表
   */
  getProductList(req: GetGoodsListRequest) {
    return this.http.post<GetGoodsListResponse>(`api/store/v1/yjdstore/goods/get_goods_list`, req);
  }

  /**
   * 新增商品
   */
  addGoods(req: AddGoodsRequest) {
    return this.http.post<AddGoodsResponse>(`api/store/v1/yjdstore/goods/add_goods`, req);
  }

  /**
   * 编辑商品
   */
  modifyGoods(req: ModifyGoodsRequest) {
    return this.http.post<ModifyGoodsResponse>(`api/store/v1/yjdstore/goods/modify_goods`, req);
  }

  /**
   * 商品上架下架删除
   */
  changeGoodsState(req: ChangeGoodsStateRequest) {
    return this.http.post<ChangeGoodsStateResponse>(`api/store/v1/yjdstore/goods/change_goods_state`, req);
  }

  /**
   * 新增商品扫码
   */
  getGoodsByBarCode(req: GetGoodsByBarCodeRequest) {
    return this.http.post<GetGoodsByBarCodeResponse>(`api/store/v1/yjdstore/goods/get_goods_by_bar_code`, req);
  }

  /**
   * 获取子分类
   */
  getGoodsClassification(req: GetGoodsClassificationRequest) {
    return this.http.post<GetGoodsClassificationResponse>(`api/store/v1/yjdstore/goods/get_goods_classification`, req);
  }

  /**
   * 商品详情
   */
  getGoodsDetail(req: GetGoodsDetailRequest) {
    return this.http.post<GetGoodsDetailResponse>(`api/store/v1/yjdstore/goods/get_goods_detail`, req);
  }
}
