/*
 * @Author: your name
 * @Date: 2020-05-18 10:21:49
 * @LastEditTime: 2020-05-27 15:04:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/shared.module.ts
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
import { AlainThemeModule } from '@delon/theme';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';

import { FilterCharDirective } from './directives/filter-char.directive';
import { SHARED_DELON_MODULES } from './shared-delon.module';
import { SHARED_ZORRO_MODULES } from './shared-zorro.module';

// #region third libs
import { GeekymedicCommonModule } from '@geekymedic/common';
import { GeekymedicCoreModule } from '@geekymedic/core';

import { BackHeaderComponent } from './components/back-header/back-header.component';
import { SearchFormCollapsibleComponent } from './components/search-form-collapsible/search-form-collapsible.component';
import { UploadImgComponent } from './components/upload-img/upload-img.component';
import { InputNoSpaceDirective } from './directives/input-nospace.directive';
const THIRDMODULES = [GeekymedicCoreModule, GeekymedicCommonModule];

// #endregion

// #region your componets & directives

const COMPONENTS = [SearchFormCollapsibleComponent, BackHeaderComponent, UploadImgComponent];
const DIRECTIVES = [InputNoSpaceDirective, FilterCharDirective];

// #endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonACLModule,
    DelonFormModule,
    ImageCropperModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    // third libs
    ...THIRDMODULES,
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonACLModule,
    DelonFormModule,
    ImageCropperModule,
    TranslateModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
})
export class SharedModule {}
