/*
 * @Author: your name
 * @Date: 2020-05-23 15:08:37
 * @LastEditTime: 2020-05-25 17:48:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/api/services/setting.service.ts
 */

import { Injectable, Injector } from '@angular/core';
import { GetBaseInfoResponse } from '@api/models/setting/GetBaseInfoResponse';
import { GetQualificationInfoResponse } from '@api/models/setting/GetQualificationInfoResponse';
import { GetSettlementInfoResponse } from '@api/models/setting/GetSettlementInfoResponse';
import { UpdateBaseInfoRequest } from '@api/models/setting/UpdateBaseInfoRequest';
import { UpdateQualificationInfoRequest } from '@api/models/setting/UpdateQualificationInfoRequest';
import { UpdateSettlementInfoRequest } from '@api/models/setting/UpdateSettlementInfoRequest';
import { BaseService } from '@geekymedic/common';

@Injectable({
  providedIn: 'root',
})
export class SettingService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }
  /**
   * 供应商基本信息
   */
  getBaseInfo() {
    return this.http.post<GetBaseInfoResponse>(`api/store/v1/yjdstore/supplier/get_base_info`, {});
  }

  /**
   * 供应商获取资质
   */
  getQualificationInfo() {
    return this.http.post<GetQualificationInfoResponse>(`api/store/v1/yjdstore/supplier/get_qualification_info`, {});
  }

  /**
   * 获取入驻信息
   */
  getSettlementInfo() {
    return this.http.post<GetSettlementInfoResponse>(`api/store/v1/yjdstore/supplier/get_settlement_info`, {});
  }

  /**
   * 上传供应商基本信息
   */
  updateBaseInfo(req: UpdateBaseInfoRequest) {
    return this.http.post<{}>(`api/store/v1/yjdstore/supplier/update_base_info`, req);
  }

  /**
   * 上传供应商资质
   */
  updateQualificationInfo(req: UpdateQualificationInfoRequest) {
    return this.http.post<{}>(`api/store/v1/yjdstore/supplier/update_qualification_info`, req);
  }

  /**
   * 上传入入驻信息
   */
  updateSettlementInfo(req: UpdateSettlementInfoRequest) {
    return this.http.post<{}>(`api/store/v1/yjdstore/supplier/update_settlement_info`, req);
  }
}
