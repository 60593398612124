/*
 * @Author: your name
 * @Date: 2020-05-18 16:00:56
 * @LastEditTime: 2020-05-18 16:31:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/components/back-header/back-header.component.ts
 */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-header',
  templateUrl: '../back-header/back-header.component.html',
  styleUrls: ['./back-header.component.scss'],
})
export class BackHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;

  constructor() {}

  ngOnInit() {}

  /**
   * @description: 返回上一级
   * @param {type}
   * @return:
   */
  onBackClick() {
    history.go(-1);
  }
}
