/*
 * @Author: your name
 * @Date: 2020-05-18 10:21:49
 * @LastEditTime: 2020-05-23 15:08:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/api/services.ts
 */

export * from './services/member.service';
export * from './services/staff.service';
export * from './services/user.service';
export * from './services/product.service';
export * from './services/setting.service';
