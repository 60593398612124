import { Component } from '@angular/core';

@Component({
  selector: 'layout-passport',
  templateUrl: './passport.component.html',
  styleUrls: ['./passport.component.less'],
})
export class LayoutPassportComponent {
  links = [
    {
      title: '深圳药极客科技有限公司',
      href: '',
    },
    {
      title: '医疗器械网络交易服务',
      href: '',
    },
    {
      title: '第三方台备案凭证的编号(粤)-经营性-2019-0135',
      href: '',
    },
  ];
}
