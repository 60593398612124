import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { UsersService } from '@api/services';
import { StartupService } from '@core';
import { ReuseTabService } from '@delon/abc';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { BaseComponent } from '@geekymedic/common';

@Component({
  selector: 'app-bind-mobile',
  templateUrl: './bind-mobile.component.html',
  styleUrls: ['./bind-mobile.component.less'],
})
export class BindMobileComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(
    injector: Injector,
    private userService: UsersService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      Tel: ['', [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      Vcode: ['', Validators.required],
    });
  }

  get Tel() {
    return this.form.controls.Tel;
  }
  get Vcode() {
    return this.form.controls.Vcode;
  }

  count = 0;
  interval$: any;
  type = 0;

  form: FormGroup;
  error = '';
  checked = true;
  WebchatCode = '';

  isBindVisible = false; // 是否绑定
  getCaptcha() {
    if (this.Tel.invalid) {
      this.Tel.markAsDirty({ onlySelf: true });
      this.Tel.updateValueAndValidity({ onlySelf: true });
      return;
    }
    this.isBindWeChat();
    // const params = {
    //   Tel: this.form.controls.Tel.value,
    // };
    // this.userService.sendBindVcode(params).subscribe((res) => {
    //   this.message.success('短信发送成功，请注意查收');
    //   this.count = 59;
    //   this.interval$ = setInterval(() => {
    //     this.count -= 1;
    //     if (this.count <= 0) {
    //       clearInterval(this.interval$);
    //     }
    //   }, 1000);
    // });
  }

  ngOnInit() {
    this.routerInfo.queryParams.subscribe((res) => {
      this.WebchatCode = res.WebchatCode;
    });
  }

  /**
   * 登录
   */
  submit() {
    if (!this.checked) {
      this.message.info('请同意协议');
      return;
    }
    // tslint:disable-next-line: forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (!this.form.valid) {
      return;
    }
    this.error = '';

    const params = {
      Tel: this.Tel.value,
      VCode: this.Vcode.value,
      WebchatCode: this.WebchatCode,
    };
    this.userService.bindMobile(params).subscribe((res) => {
      // 清空路由复用信息
      this.reuseTabService.clear();
      // 设置用户Token信息
      this.tokenService.set({ token: res.Token, id: res.StaffId });
      // 存储user到缓存中
      localStorage.setItem('userInfo', JSON.stringify(res));
      this.getUser();
    });
  }

  getUser() {
    this.userService.getInfo().subscribe((res) => {
      localStorage.setItem('user', JSON.stringify(res));
      // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
      this.startupSrv.load().then(() => {
        // tslint:disable-next-line: no-non-null-assertion
        let url = this.tokenService.referrer!.url || '/';
        if (url.includes('/passport')) {
          url = '/';
        }
        this.router.navigateByUrl(url);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.interval$) {
      clearInterval(this.interval$);
    }
  }

  isBindWeChat() {
    const params = {
      Tel: this.Tel.value,
      VCode: '',
      WebchatCode: this.WebchatCode,
    };
    this.userService.isBindPhone(params).subscribe((res) => {
      // tslint:disable-next-line: no-console
      if (res.IsBind) {
        // tslint:disable-next-line: no-console
        this.isBindVisible = true;
      } else {
        this.sendVcoded();
      }
    });
  }

  sendVcoded() {
    if (this.Tel.invalid) {
      this.Tel.markAsDirty({ onlySelf: true });
      this.Tel.updateValueAndValidity({ onlySelf: true });
      return;
    }
    // tslint:disable-next-line: no-shadowed-variable
    const params = {
      Tel: this.form.controls.Tel.value,
    };
    this.userService.sendBindVcode(params).subscribe((res) => {
      this.message.success('短信发送成功，请注意查收');
      this.count = 59;
      this.interval$ = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) {
          clearInterval(this.interval$);
        }
      }, 1000);
    });
  }

  handleCancel() {
    this.isBindVisible = false;
  }

  handleOk() {
    this.isBindVisible = false;
    this.sendVcoded();
  }
}
