<!--
 * @Author: your name
 * @Date: 2020-05-18 16:00:56
 * @LastEditTime: 2020-05-18 16:17:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/components/back-header/back-header.component.html
-->
<nz-page-header class="back-header-body" (nzBack)="onBackClick()" nzBackIcon nzTitle="{{title}}"
  nzSubtitle="{{subTitle}}"> </nz-page-header>
