<div>
  <h3 class="head-title">"用户隐私协议"</h3>
  <div class="content-text">
    <p>
      欢迎您使用药极订！
      我们非常重视您的隐私保护和个人信息保护。我们致力于维持您对我们的信任，恪守适用法律和我们对您的承诺，尽全力保证您的个人信息安全和合理使用。同时，
      我们郑重承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
      为帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据，我们订立了本协议。若您使用药极订的服务，表示您认同
      我们在本协议中所述内容。在将您的信息用于本协议未涵盖的用途时，我们会事先征求您的同意。
      本隐私协议将帮助您了解以下内容：
      1. 我们如何收集和使用您的个人信息
      2. 我们如何共享、转让、公开披露您的个人信息
      3. 我们如何保存及保护您的个人信息
      4. 您的权利
      5. 您的个人信息如何跨境转移
      6. 我们如何处理未成年人的个人信息
      7. 隐私政策的修订
      8. 如何联系我们
      一、我们如何收集和使用您的个人信息
      药极订仅会出于本隐私协议所述的以下目的，收集和使用您的个人信息：
      (1)帮助您成为我们的注册用户
      您注册药极订帐号吋须至少向我们提供手机号码。
      您提供的上述信息，将在您使用药极订产品和服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息或对您的个人信息进行匿名化处理，法律法规另有规定的除外。
      (2)向您提供产品和服务
      1.您向我们提供的信息
      您使用药极订的特定业务类型时，为满足向您提供产品和服务之目的，除注册时提供的信息外，您可能还需要进一步向我们提供与上述产品和服务的功能相关的信息
      (例如您的真实姓名、性别、出生曰期、身份证、联系地址/家庭住址），为了验证您提供信息的准确性和完整性，或保障
      您的帐，安全，我们会与合法留存您的信息并与国家机关、金融机构、企事业单位进行核对。如果您不使用特定产品和服务，则无需提供相关信息。
      当您使用实名认证功能吋，我们会收集您的真实姓名及身份证号信息。您应按照页面提示文案输入姓名与身份证号码，您同意药极订将您向药极订提供的姓名及身份证号码与法律法规允许的机构或政府机关授权的机构进行对比核验。如果您不使用实名认证功能，则无需提供相关信息
      我们还可能会记录您在使用药极订时提供、形成或留存的信息。例如您在使用药极订办理业务时输入的搜索关键词和点击的链接、业务收藏记录。您有权选择不提供
      前述信息，但这将导致您可能无法使用特定服务或功能，或者无法达到相关服务拟达到的效果。
      2．我们在您使用服务过程中收集的信息
      我们可能会收集关于您使用产品和/或服务（包括使用方式）过程中的信息，此类信息包括：
      (1) 设备信息
      为了提供更好的产品和/或服务并改善用户体验，我们
      会收集常用设备属性信息（例如您的硬件型号）。对于您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将您的设备信息或电话号码与您的药极订帐户相关联。
      (2)曰志信息
      当您使用药极订时，我们的服务器会自动记录一些信息，
      例如您对药极订的使用情况，包括访问服务的日期、时 间、吋长等。
      (3)位置信息
      当您使用具有定位功能的药极订应用服务时，当您开启设备定位功能并使用我们基于位置提供的相关服务时，我们可能会|收集和处理有关您实际所在位置的信息，以使得您不需|要手动输入自身地理坐标就可获得相关服务。您可以通过关闭定位功能，停止对您的地理位置信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。
      (4)相册信息
      当您使用图片上传、扫一扫服务时，我们可能会读取您的相册信息，以使得您快速选择上传或读取所需照片。您可以通过关闭相册读取权限功能，停止对您的相册信 息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。
      (5)语音信息
      当您使用语音搜索服务时，我们可能会读取您的语音信息，以使得您不需要手动输入搜索管检测就可获得相应搜索结果。您可以通过关闭麦克风读取权限功能，停止 对您的语音信息的收集，但您可能将无法获得相关服务
      或功能，或者无法达到相关服务拟达到的效果。
      (6)用户画像信息
      当您使用APP内服务时，我們将收集您的服务使用频率 以及您使用的高频服务事项名称，并根据您在实名认证时提供的身份证号，以及您在个人中心设置的个人表签、地址信息、性别、职业信息进行个性化人物画像，
      以便为您推荐更适合您的服务。
      3. 我们通过间接获得方式收集到的您的个人信息
      当您通过药极订平台与药极订关联方、合作方服务时，您同意我们根据实际业务及合作需要从我们关联方、合作方处接收、使用、汇总、分析经您授权同意其向我们提供的您的个人信息。
      （三）与您沟通
      我们会利用收集的信息（例如您的手机号码、电子邮件地址）直接与您沟通或互动。例如，如果我们或我们的关联公司检测到异常登录活动。
      （四）改善和运营药极订产品和服务
      我们使用收集的信息来提供并改进我们及药极订关联方、合作方提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务，评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。
      为改进我们及药极订关联方、合作方的产品和服务，我们也可能会对产品使用情况进行统计和分析。同时，药极订可能会与关联方、合作方、公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。
      （五）其他用途
      当我们要将信息用于本隐私协议未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
      二、我们如何共享、转让、公开披露您的个人信息
      （一） 共享
      我们承诺对您的信息予以严格保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括我们的关联公司以及其他合作伙伴。
      1.为了让您使用药极订的服务，我们需要向您收集必要的个人信息；对于某些由第三方或由我们与第三方共同提供的服务，只有共享您的信息，我们才能提供您需要的服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享与提供服务相关的个人信息。
      2.如果您选择参与我们和第三方联合开展的推广活动，比如优惠券领取、抽奖，我们可能与合作的第三方共享活动过程中产生，为完成活动所必要的信息，以便第三方能及时向您提供服务或发放奖品。我们会依据国家法律法规或国家标准的要求，在活动页面或通过其他途径向您明确告知需要向第三方提供的信息类别。
      3.对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私协议以及其他任何相关的保密和安全措施来处理个人信息。
      我们希望尽可能详尽地罗列出我们可能需要共享信息的业务类型，您可以在以下内容中查询到您在使用药极订某项具体服务时，会发生的信息共享情形。我们会尽全力保持更新和透明，以保障您的知情权。
      （二）转让
      我们不会将您的个人信息转让给除药极订及其关联方外的任何公司、组织和个人，但以下情形除外：
      1.事先获得您的明确授权或同意；
      2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；
      3.如果我们涉及合并、分立，您的个人信息有可能作为此类活动的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的主体继续受此隐私协议的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      （三）公开披露
      原则上我们不会将您的信息进行公开披露。如确需公开披露，我们会向您告知公开披露的目的，披露信息的类型以及可能涉及的敏感信息，并征得您明确同意。
      （四）根据相关法律法规和国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息而无需征得您的同意：
      1. 与国家安全、国防安全直接相关的；
      2. 与公共安全、公共卫生、重大公共利益直接相关的；
      3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；
      4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      5. 您自行向社会公众公开的个人信息；
      6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      7. 根据个人信息主体要求签订和履行合同所必需的；
      8. 用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
      9. 法律法规规定的其他情形。
      根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
      三、我们如何保存及保护您的个人信息
      （一）保存期限
      您在使用药极订产品及服务期间，我们将持续为您保存您的个人信息。如果您注销帐户或主动删除上述信息，我们将依据网络安全法等法律法规规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行匿名化处理后使用。
      （二）保存地域
      您的个人信息均储存于中华人民共和国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。
      （三）安全措施
      1. 我们会以业界成熟的安全标准和规范收集、使用、存储和传输用户信息，并通过用户协议和隐私协议告知您相关信息的使用目的和范围。
      2.
      我们非常重视信息安全。我们委派了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取符合业界标准的合理可行的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、修改、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
      3.
      我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们部署访问控制机制，对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。
      4.
      在个人信息安全事件时，我们会立即启动应急预案，采取相应的补救措施，在最短时间内追溯原因并减少损失。与此同时，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
      5. 我们提醒您注意，互联网并非绝对安全的环境，请注意确保您个人信息的安全，请使用复杂密码，协助我们保证您的账号以及个人信息安全。
      四、您的权利
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
      （一）访问您的个人信息
      您有权访问您的个人信息，法律法规规定的例外情况除外。如果您无法访问个人信息，您可以联系我们反馈问题。
      （二）更正您的个人信息
      您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以与我们联系反馈。为保障安全，我们将在您行使更正权前对您的身份进行验证。
      （三）删除您的个人信息
      在以下情形中，您可以向我们提出删除个人信息的请求：
      1. 如果我们违法法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；
      2. 如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。
      当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。 
      （四）改变您授权同意的范围
      如您想改变授权范围，您可通过联系我们或修改、关闭您设备的隐私权限修改授权范围。
      当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
      （五）注销您的账户
      您随时可注销此前注册的账户，注销方式请联系我们，一旦您注销药极订账号，将无法使用药极订。因此请您谨慎操作。
      （六）提前获知药极订停止运营
      药极订愿一直陪伴您，若因特殊原因导致药极订平台产品和服务被迫停止运营，我们将按照法律法规要求在药极订主页面向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
      （七）响应您的上述请求
      为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
      在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：
      1. 与国家安全、国防安全直接相关的；
      2. 与公共安全、公共卫生、重大公共利益直接相关的；
      3. 与犯罪侦查、起诉、审判和执行判决等直接相关的；
      4. 我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；
      5. 响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；
      6. 涉及商业秘密的。
      五、我们如何处理未成年人的个人信息
      药极订非常重视对未成年人信息的保护。
      我们的服务主要面向成人。若您是未成年人，建议您请您的监护人仔细阅读本隐私协议，并在征得您的监护人同意的前提下使用药极订。
      如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
      如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请联系我们，同时提交相关材料以便我们验证身份，我们会尽快审核并设法尽快删除相关数据。
      六、您的个人信息如何进行跨境转移
      原则上，我们在中国境内收集和产生的个人信息，将存储在中国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。
      七、本隐私协议如何更新
      本协议自您以点击或其他方式确认同意之时生效。
      我们可能适时修订本协议内容。如该等变更会导致您在本协议项下权利的实质减损，我们将在变更生效前，通过在页面显著位置提示、向您发送电子邮件等方式通知您。我们会在本页面上发布对本隐私协议所做的任何变更。
      若您不同意该等变更可以停止使用药极订，若您继续使用药极订，即表示您同意受修订后的本隐私协议的约束。
      本隐私协议所指的重大变更包括但不限于：
      1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      2. 个人信息共享、转让或公开披露的主要对象发生变化；
      3. 您参与个人信息处理方面的权利及其行使方式发生重大变化；
      4. 其他可能对您的个人信息权益产生重大影响的变化时。
      八、如何联系我们
      如您对本隐私协议有任何意见或建议，可通过以下方式联系我们：
      1.平台设置的用户反馈通道
      2.客服电话：0755-26412925
      3.联系我们的所在地：深圳市南山区深南大道9966号威盛科技大厦1211我们会在验证您的身份后处理您的请求。一般情况下，我们将在【15】天内回复。
      如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求结局方案：向深圳国际仲裁院（深圳仲裁委员会）申请终局性裁决。
      附录1：定义
      本隐私协议中使用的特定词语，具有如下含义：
      1. “我们”或“药极订”，指深圳药极客科技有限公司。
      2. “您”，指药极订注册用户。
      3. “药极订平台”，指药极订客户端。
      4. “药极订关联方、合作方”，指我们的关联公司、投资公司、联盟成员、合作伙伴及其他受信任的第三方供应商、服务商及代理商。
      5.
      “个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。
      6. “个人信息主体”，指个人信息所标识的自然人。
      7.
      “个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息、个人身份信息、网络身份标识信息等。
      8. “去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。
      9. “匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
      10.“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本隐私协议之目的，不包含香港特别行政区、澳门特别行政区和中国台湾地区。
    </p>
  </div>
</div>
