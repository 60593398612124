import { Component, Inject, Injector, OnDestroy, Optional } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { StartupService } from '@core';
// tslint:disable-next-line: import-blacklist
import { ReuseTabService } from '@delon/abc';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ModalHelper } from '@delon/theme';
import { BaseComponent } from '@geekymedic/common';
import { UsersService } from 'src/app/api/services/user.service';
import { PrivacyAgreementComponent } from '../privacy-agreement/privacy-agreement.component';
import { ServiceAgreementComponent } from '../service-agreement/service-agreement.component';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.less'],
})
export class MobileLoginComponent extends BaseComponent implements OnDestroy {
  constructor(
    injector: Injector,
    private userService: UsersService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    private modalHlp: ModalHelper,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      Tel: ['', [Validators.required, Validators.pattern(/^1\d{10}$/)]],
      Vcode: ['', Validators.required],
    });
    this.modal.closeAll();
  }

  get Tel() {
    return this.form.controls.Tel;
  }
  get Vcode() {
    return this.form.controls.Vcode;
  }

  count = 0;
  interval$: any;
  type = 0;

  form: FormGroup;
  error = '';
  checked = true;

  isVisible = false;
  // #endregion

  switch(ret: any) {
    this.type = ret.index;
  }

  getCaptcha() {
    if (this.Tel.invalid) {
      this.Tel.markAsDirty({ onlySelf: true });
      this.Tel.updateValueAndValidity({ onlySelf: true });
      return;
    }
    console.log('mobile=>' + this.Tel.value);
    const params = {
      Tel: this.form.controls.Tel.value,
    };
    this.userService.sendVcode(params).subscribe((res) => {
      this.message.success('短信发送成功，请注意查收');
      this.count = 59;
      this.interval$ = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) {
          clearInterval(this.interval$);
        }
      }, 1000);
    });
  }

  /**
   * 登录
   */
  submit() {
    if (!this.checked) {
      this.message.info('请同意协议');
      return;
    }
    // tslint:disable-next-line: forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }
    if (!this.form.valid) {
      return;
    }
    this.error = '';

    const params = {
      Tel: this.Tel.value,
      VCode: this.Vcode.value,
    };
    this.userService.mobileLogin(params).subscribe((res) => {
      // 清空路由复用信息
      this.reuseTabService.clear();
      // 设置用户Token信息
      this.tokenService.set({ token: res.Token, id: res.StaffId });
      // 存储user到缓存中
      localStorage.setItem('userInfo', JSON.stringify(res));
      this.getUser();
    });
  }

  getUser() {
    this.userService.getInfo().subscribe((res) => {
      localStorage.setItem('user', JSON.stringify(res));
      // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
      this.startupSrv.load().then(() => {
        // tslint:disable-next-line: no-non-null-assertion
        let url = this.tokenService.referrer!.url || '/';
        if (url.includes('/passport')) {
          url = '/';
        }
        this.router.navigateByUrl(url);
      });
    });
  }

  /**
   * 服务协议
   */
  goServiceAgreement() {
    this.modalHlp.static(ServiceAgreementComponent).subscribe(() => {});
  }

  /**
   * 隐私协议
   */
  goPrivacyAreement() {
    this.modalHlp.static(PrivacyAgreementComponent).subscribe(() => {});
  }

  ngOnDestroy(): void {
    if (this.interval$) {
      clearInterval(this.interval$);
    }
  }
}
