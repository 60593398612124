<div class="container">
  <header-i18n showLangText="false" class="langs"></header-i18n>
  <div class="wrap">
    <div class="top mb-lg">
      <div class="head">
        <img class="logo" src="./assets/yjd-logo-blue.png">
        <span class="title">供应商管理后台</span>
      </div>
    </div>
    <router-outlet></router-outlet>
    <global-footer [links]="links">
    </global-footer>
  </div>
</div>