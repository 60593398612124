import { Component, OnInit } from '@angular/core';
import { UsersService } from '@api/services';
import { _HttpClient } from '@delon/theme';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
  constructor(private http: _HttpClient, private userService: UsersService) {}

  ngOnInit() {
    this.userService.getInfo().subscribe((res) => {
      localStorage.setItem('user', JSON.stringify(res));
    });
  }
}
