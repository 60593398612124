import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

const CODEMESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  private get notification(): NzNotificationService {
    return this.injector.get(NzNotificationService);
  }
  private get msg(): NzMessageService {
    return this.injector.get(NzMessageService);
  }
  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase) {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }
  }

  private handleData(ev: HttpResponseBase): Observable<any> {
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    if (ev.status > 0) {
      this.injector.get(_HttpClient).end();
    }
    this.checkStatus(ev);
    // 业务处理：一些通用操作
    switch (ev.status) {
      case 200:
        // 业务层级错误处理，以下是假定restful有一套统一输出格式（指不管成功与否都有相应的数据格式）情况下进行处理
        // 例如响应内容：
        //  错误内容：{ status: 1, msg: '非法参数' }
        //  正确内容：{ status: 0, response: {  } }
        // 则以下代码片断可直接适用
        if (ev instanceof HttpResponse) {
          const body: any = ev.body;
          if (body && body.hasOwnProperty('Code')) {
            if (body.Code !== 0) {
              this.injector.get(NzMessageService).error(body.Message);
              // token失效||用户不存在
              if (body.Code === 1005 || body.Code === 100001) {
                this.injector.get(Router).navigateByUrl('/login');
              } else {
                switch (body.Code) {
                  case 1004:
                    this.injector.get(NzMessageService).error(body.Message);
                    break;
                  case 1008:
                    this.injector.get(NzMessageService).error(body.Message);
                    break;
                }
              }
              console.log(body.Code + '<==code');
              // 继续抛出错误中断后续所有 Pipe、subscribe 操作，因此：
              // this.http.get('/').subscribe() 并不会触发
              return throwError({});
            } else {
              // 重新修改 `body` 内容为 `response` 内容，对于绝大多数场景已经无须再关心业务状态码
              return of(new HttpResponse({ ...ev, body: body.Data }));
              // 或者依然保持完整的格式
              // return of(event);
            }
          }
        }
        break;
      case 401:
        this.notification.error(`未登录或登录已过期，请重新登录。`, ``);
        // 清空 token 信息
        (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
        this.goTo('/passport/login');
        break;
      case 403:
      case 404:
      case 500:
        console.log(ev.status + '<==code');
        // this.goTo(`/exception/${ev.status}`);
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
        }
        break;
    }
    if (ev instanceof HttpErrorResponse) {
      return throwError(ev);
    } else {
      return of(ev);
    }
  }
  /**
   * @description: 重置请求参数
   * @params {req: HttpRequest}
   * @return: HttpRequest
   */
  private resetOptions(req: HttpRequest<any>): HttpRequest<any> {
    // 统一加上服务端前缀
    let url = req.url;
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = environment.SERVER_URL + url;
    }
    const headers = req.headers.set('Content-Type', 'application/json');
    let params;

    if (!url.endsWith('login')) {
      const timeStap = new Date().getTime() + '';
      // tslint:disable-next-line: radix
      const randomNum = parseInt(Math.random() * 10000 + '') + timeStap;
      const userInfo = localStorage.getItem('userInfo');
      const userInfoObj = JSON.parse(userInfo);
      const sequence = localStorage.getItem('sequence') || '1';
      if (userInfo) {
        params = req.params
          .set('_storeId', userInfoObj.Store)
          .set('_token', userInfoObj.Token)
          .set('_uid', userInfoObj.Uid)
          .set('_platform', 'webYJDGYS')
          .set('_version', 'v0.0.1')
          .set('_trace', randomNum + '')
          .set('_sequence', sequence)
          .set('_time', timeStap);
        const numSequence = Number(sequence) + 1;
        localStorage.setItem('sequence', numSequence + '');
      }
    }
    return req.clone({ url, params });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    // let url = req.url;
    // if (!url.startsWith('https://') && !url.startsWith('http://')) {
    //   url = environment.SERVER_URL + url;
    // }

    const newReq = this.resetOptions(req);
    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理
        if (event instanceof HttpResponseBase) {
          return this.handleData(event);
        }
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err)),
    );
  }
}
