/*
 * @Description: 校验器集合
 * @Author: wangzhihao
 * @Contact: zhihao.wang@geekymedic.cn
 * @Date: 2019-07-29 11:35:00
 * @LastEditTime: 2020-05-27 14:36:54
 * @LastEditors: Please set LastEditors
 */

import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Validate } from './validate';

function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

// tslint:disable-next-line: class-name
export class _Validators {
  /**
   * 验证手机号
   */
  static mobile(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return Validate.isMobile(control.value) ? null : { mobile: true };
  }
  /**
   * 验证银行卡号
   */
  static bankCard(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return Validate.isBankCard(control.value) ? null : { bankcard: true };
  }
  /**
   * 验证身份证号
   */
  static idNum(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return Validate.isIdNum(control.value) ? null : { idnum: true };
  }
  /**
   * 验证url
   */
  static url(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return Validate.isUrl(control.value) ? null : { url: true };
  }
  /**
   * 验证营业执照
   */
  static businessLicense(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return Validate.isBusinessLicense(control.value) ? null : { businessLicense: true };
  }

  /**
   * 验证邮箱
   */
  static email(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return Validate.isEmail(control.value) ? null : { email: true };
  }

  /**
   * 是否为空
   */
  static isEmpty(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return Validate.isEmpty(control.value) ? null : { empty: true };
  }
}
