import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { LazyService } from '@geekymedic/core';
import { NzMessageService } from 'ng-zorro-antd';
// import * as OSS from 'ali-oss';
import { from, Observable, observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UploadImageService {
  loadPath = 'https://gosspublic.alicdn.com/aliyun-oss-sdk-6.1.1.min.js';
  client;
  clientConfig = {
    accessKeyId: 'LTAIcfssV4GnR2Gx',
    accessKeySecret: '3dF3D6cSNcAny9bnfJ9pHVrkrLzbtU',
    bucket: 'geeky-test-oss-1',
    region: 'oss-cn-shenzhen',
  }; // OSS配置

  uploadConfig = {
    system: 'store', // 系统名:boss/store
    id: 'Uid', // boss用的是用户登陆信息的Uid,store用的是用户登陆信息的ShowId,
    fileName: '', // 上传图片名
    router: '', // 当前上传的路由，通过它来分文件夹
  };

  constructor(
    private routerInfo: ActivatedRoute,
    private localtion: Location,
    private lazyService: LazyService,
    private message: NzMessageService,
  ) {
    this.resetConfig();
    this.loadOSS();
  }

  /**
   * @description: 重置参数
   * @params: {}
   * @return:
   */
  resetConfig() {
    this.clientConfig.bucket = environment.production ? 'yaodao-oss' : 'geeky-test-oss-1';
    const user = localStorage.getItem('userInfo');
    user ? (this.uploadConfig.id = JSON.parse(user).Uid) : (this.uploadConfig.id = '000');
    let url = this.localtion.path();
    url = url.indexOf('?') > -1 ? url.split('?')[0] : url;
    url ? (this.uploadConfig.router = url) : (this.uploadConfig.router = '/common');
  }

  /**
   * @description: 懒加载OSS
   * @params: {}
   * @return:
   */
  loadOSS() {
    this.lazyService.loadScript(this.loadPath).subscribe({
      next: (res) => {
        this.client = new OSS(this.clientConfig);
      },
      error: (err) => {
        this.message.error('OSS加载失败，请刷新重试');
      },
    });
  }

  /**
   * @description: 配置默认bucket
   * @params: {}
   * @return:
   */
  setDefaultBucket() {
    this.clientConfig.bucket = environment.production ? 'yaodao-oss' : 'geeky-test-oss-1';
    this.client = new OSS(this.clientConfig);
  }

  /**
   * @description: 配置支付bucket
   * @params: {}
   * @return:
   */
  setPayBucket() {
    this.clientConfig.bucket = environment.production ? 'geeky-pay-oss' : 'geeky-pay-test';
    this.client = new OSS(this.clientConfig);
  }

  /**
   * @description: 生成过期图片，主要用在支付加密
   * @params: {url 路径}
   * @return:
   */
  getEncryptionImg(name) {
    const url = this.client.signatureUrl(name, {
      expires: 3600, // 过期时间1个钟
    });
    return url;
  }

  /**
   * @description: 上传图片
   * @params: {file:文件}
   * @return:
   */
  upload(file) {
    const date = new Date();
    this.uploadConfig.fileName = date.getTime() + '.jpg';
    const path = `${this.uploadConfig.system}/${this.uploadConfig.id}${this.uploadConfig.router}/${this.uploadConfig.fileName}`;
    const obs = new Observable((observer) => {
      this.client
        .put(path, file)
        .then((result) => {
          observer.next(result);
        })
        .catch((err) => {
          if (err) {
            observer.error(err);
          } else {
            observer.complete();
          }
        });
    });
    return obs;
  }

  /**
   * @description: 删除图片
   * @params: {path:路径}
   * @return:
   */
  delete(path) {
    return new Observable((subscriber) => {
      this.client
        .delete(path)
        .catch((err) => {
          if (err) {
            subscriber.error(err);
          }
        })
        .then((result) => {
          subscriber.next(result);
        });
    });
  }
}
