import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appFilterChar],textarea[appFilterChar]',
})
export class FilterCharDirective implements OnInit {
  constructor(private control: NgControl) {}
  private _appFilterChar: boolean;
  @Input()
  get appFilterChar(): boolean | string {
    return this._appFilterChar;
  }
  set appFilterChar(value: boolean | string) {
    this._appFilterChar = value != null && value !== false && `${value}` !== 'false';
    if (typeof value === 'string' && this.regObj[value]) {
      this.regexp = this.regObj[value];
    }
  }
  @Input() regexp: string;
  private regExp: RegExp;
  private formControl: FormControl;
  private regObj = {
    special: '[^A-Za-z0-9]',
    whitespace: '^\\s*|\\s*$',
  };
  ngOnInit(): void {
    this.regExp = new RegExp(this.regexp || this.regObj.special, 'g');
    this.formControl = this.control.control as FormControl;
  }
  @HostListener('keyup', ['$event']) onKeyup(event) {
    if (!this.appFilterChar) {
      return;
    }
    const value = event.target.value || '';
    const newValue = value.replace(this.regExp, '');
    if (this.formControl) {
      this.formControl.patchValue(newValue);
    } else {
      event.target.value = newValue;
    }
  }
}
