import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
// single pages
import { CallbackComponent } from './callback/callback.component';
// dashboard pages
import { DashboardComponent } from './dashboard/dashboard.component';
import { BindMobileComponent } from './passport/bind-mobile/bind-mobile.component';
// passport pages
import { MobileLoginComponent } from './passport/mobile-login/mobile-login.component';
import { WechatLoginComponent } from './passport/wechat-login/wechat-login.component';
import { RouteRoutingModule } from './routes-routing.module';

const COMPONENTS = [
  DashboardComponent,
  // passport pages
  WechatLoginComponent,
  MobileLoginComponent,
  BindMobileComponent,
  // single pages
  CallbackComponent,
];

@NgModule({
  imports: [SharedModule, RouteRoutingModule],
  declarations: [...COMPONENTS],
})
export class RoutesModule {}
