<div>
  <h3 class="head-title">用户服务协议</h3>
  <div class="content-text">
    <p>
      “药极订”是深圳药极客科技有限公司（以下简称“药极订”）。该应用秉承“消费者为中心”的理念，汇聚药店商品信息让市民足不出户就可以享受到方便快捷、统一规范、优质高效的信息服务，实现服务便民利民、办事依法依规、信息公开透明。
      下述“药极订”的表述，指代“药极订”服务；“我们”指代“药极订”服务提供者；“用户”是指使用“药极订”相关服务的使用人，在本协议中更多地称为“您”。
      在使用“药极订”之前，请您务必审慎阅读、充分理解《“药极订”用户服务协议》（以下简称“本协议”），特别是限制或免除责任的相应条款、法律适用和争议解决条款。限制或免除责任的相应条款以加粗或下划线形式提示您注意，未成年人应在法定监护人的陪同下阅读。
      您点击“阅读并同意注册协议”按钮即表示您确认对本协议全部条款含义已充分理解并完全接受，且同意我们有权在必要时修改本协议条款并按法律规定公示，您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用“药极订”提供的服务，即视为已接受修改后的协议。如果您不接受修改后的协议，您应当停止使用“药极订”。
      第一条 协议的范围
      本协议是您与“药极订”的服务者关于您获取、安装、登录、使用“药极订”服务（包括但不限于PC端、移动端设备等方式登录并使用“药极订”服务）所订立的协议。
      第二条 “药极订”软件的获取和更新
      一、软件的获取
      1．您可以直接从“药极订”的运营人员获取本软件，也可以从得到“药极订”授权的第三方获取。
      2． 如果您从未经“药极订”授权的第三方获取本软件或与本软件名称相同的安装程序，“药极订”无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
      二、 软件的更新
      1．为了改善用户体验、完善服务内容，我们将不断努力开发新的服务，为您提供软件更新（这些更新可能采取软件替换、修改、功能强化、版本升级等形式）。
      2．本软件新版本发布后，旧版本的软件可能无法使用。我们不能保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。
      第三条 账户使用规则
      一、用户注册
      1．您在注册或使用“药极订”时可能需要提供一些必要的信息。您须保证所填写的注册表和所提供的资料真实、有效、准确、完整，否则将无法使用“药极订”或在使用过程中受到限制。因您提供的信息不真实、不准确或不完整导致的损失由您承担。
      2．“药极订”有权根据您所提供注册资料的真实性、准确性、完整性以及是否符合“药极订”规定的其他条件，决定是否接受您的注册。您在使用“药极订”更高级别服务时，为了给您提供更好的服务及为了您的账户安全，可能需要您补充完整信息后方可使用。若您在使用“药极订”过程中所提供的注册信息发生变动，应及时进行变更。
      3．您自愿注册并使用“药极订”，确认以“药极订”的技术操作方式管理账户。
      4．未成年人在注册使用“药极订”的服务前，应事先取得监护人的同意。监护人的同意包括但不限于口头的、书面的或者其他形式。监护人同意的情形下，监护人应承担未成年人在网站隐私权保护的首要责任，且未成年人及其监护人应承担使用本服务而导致的一切后果。
      二、用户名和密码安全
      您须妥善保管本人的手机号及装载和使用“药极订”应用的设备。凡使用该手机号创建的账号的行为，“药极订”视为您本人的操作，该操作所产生的电子信息记录均为“药极订”用户行为的有效凭据。您对利用该手机号创建的账号所进行的一切活动负全部责任。如您怀疑他人在使用您的账户，您应立即通知服务提供方。
      您应按照保密的原则设置和保管密码，包括但不限于:
      1．在设备上输入验证码时，应防止旁人或不安全设备窥视；
      2．不应在网吧等公众场所的或他人的电脑或网络设备上使用“药极订”；
      3．结束使用“药极订”，应选择安全退出方式终止当前服务；
      4．发现验证吗泄漏、遗忘，应及时重新获取验证码。
      三、暂停和注销账户
      您可直接联系我们进行暂停或注销“药极订”及变更注册信息等操作。您暂停“药极订”后，在暂停期间将不能使用“药极订”，待暂停期结束后，方可重新使用；“药极订”用户注销“药极订”后，其用户资格将被注销，须重新申请注册“药极订”方可使用。
      四、用户应当自行支付使用本软件可能产生的流量费、信息费等通讯费用。
      第四条 用户的禁止行为
      一、
      我们致力于为用户提供文明健康、规范有序的网络环境，您使用“药极订”时，不得利用“药极订”帐号或本软件及服务制作、复制、发布、传播如下干扰“药极订”正常运营，以及侵犯其他用户或第三方合法权益的内容。“药极订”的系统记录可作为实施该行为的证据。禁止从事的行为包括但不限于：
      1．发布、传送、传播、储存危害国家安全统一、损害国家荣誉和利益、破坏民族团结、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
      2．发布、传播、散布任何搔扰性的、中伤辱骂性的、恐吓教唆性的垃圾信息和谣言信息，扰乱社会秩序，破坏社会稳定；
      3．发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
      4．侵害他人隐私、名誉、个人信息或资料权益等；
      5．将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序等资料，加以上传、张贴、发送电子邮件或以其他方式传送；
      6．恶意登录、注册，恶意使用、篡改系统功能及服务效果；干扰或破坏“药极订”服务或与“药极订”服务相连的服务器和网络，或不遵守“药极订”服务网络使用的规定；
      7．恶意诋毁或损害“药极订”声誉、利用“药极订”用户身份从事违反国家法律法规活动等行为；
      8．违反任何相关的中国法律、行政法规、规章、条例等具有法律效力的规范。
      二、如果我们发现或收到他人举报您发布的信息违反本条约定，我们有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，我们有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用等措施并追究法律责任。
      第五条 服务的变更、中断或终止
      一、您理解，为了保证本软件及服务的安全性和功能的一致性，我们需要定期或不定期对提供服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或维护，或对“药极订”软件及其系统进行更新、升级、改造，或者对软件的部分功能效果进行变更、暂停、限制、终止或撤销。如因此类情况而造成服务在合理时间内的中断，“药极订”无需为此承担任何责任。但我们将尽可能事先进行通告并根据情况在相应服务页面提前作出通知。
      二、如发生下列任何一种情形，我们有权不经通知而中断或终止向您提供本协议项下的服务而无需对您或任何第三方承担任何责任：
      1．根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
      2．您违反相关法律法规或本协议的约定；
      3．按照法律规定或主管部门的要求；
      4．出于安全的原因或其他必要的情形。
      三、您有责任自行备份存储在本服务中的数据。如果您的服务被终止，我们可以从服务器上永久地删除您的数据，但法律法规另有规定的除外。服务终止后“药极订”没有义务向您返还数据。
      第六条 授权条款
      一、您同意：为更有效地向您提供服务，
      “药极订”有权将您注册及使用本服务过程中所提供、形成的信息提供给本系统对接的相关政府部门或公用事业单位等合作单位，“药极订”不会向您所使用服务所涉及合作单位之外的其它方公开或透露您的个人资料，法律法规要求的除外。
      上述所称的合作单位是指（1）用户使用“药极订”或要求“药极订”提供特定服务时，“药极订”可能会调用的第三方系统或通过第三方支持用户的使用或访问；（2）其他为实现、提升“药极订”产品功能而开展合作的第三方。
      “药极订”及其合作单位对用户信息负有保密义务，并采取各种措施保证信息安全。
      二、未经用户授权同意，“药极订”不会向任何第三方公司、组织和个人披露用户的个人信息，但法律法规另有规定的除外。
      第七条 免责条款
      一、您理解并同意：“药极订”在以下情况下不承担责任（包括但不限于）：
      1.对于受到计算机病毒、木马或其他恶意程序、黑客攻击所造成的损失。
      2.对您保管及使用账户用户名、密码不善造成的损失。
      3.如果您在共享环境下或在电脑被远程监控的情况下登录“药极订”，由此造成的损失。
      4. “药极订”用户通过移动通讯设备登录相关网站和使用相关服务，因通讯过程中导致信息的不完整、丢失或被第三方拦截等，“药极订”不承担责任。
      5.如果“药极订”存在投入使用时的科学技术水平尚不能发现的缺陷，由此造成的损失“药极订”不承担责任。
      二、“药极订”不允许您冒用他人身份信息注册，否则，因此产生的法律责任将由您本人承担，给“药极订”或其他第三方造成损失的，您应给予赔偿。
      三、因无法预见、无法避免且无法克服的不可抗力事件，导致“药极订”在本协议项下的义务无法履行或无法按时履行，“药极订”在法律允许的范围内免责。
      第八条 知识产权声明
      一、“药极订”在本服务中所包含的内容（包括但不限于网页、文字、图片、音频、视频、图表等）及提供本服务时所依托软件的著作权、专利权及其他知识产权均归“药极订”服务提供者及合作单位所有。
      二、本服务包含的内容的知识产权均受到法律保护，您若侵犯“药极订”相关知识产权将承担损害赔偿责任。
      第九条 隐私权保护规则
      “药极订”承诺采取严格的隐私政策，尊重并保护用户隐私。为更好提供服务及改进服务质量，“药极订”将按照本隐私权保护规则（以下简称“本规则”）获取、使用您的个人信息。
      一、当您使用“药极订”服务时，您会向我们主动提供一些个人信息，您同意并许可我们获取您的一些信息，以向您提供服务。
      1．为了遵守法律法规的要求，以及基于实现本服务协议目的之合理需要，我们需要识别您的身份。在您注册并使用“药极订”时，您可能要向我们提供一些个人身份资料，例如姓名、证件类型和证件号码、手机号码、联系地址、电子邮件地址等。为向您提供更个性化、更便捷的服务，我们可能需要您提供其他信息，包括但不限于年龄、生日、职业、教育程度等，以及其他资料如使用的语言种类、喜好等。
      2．当您访问“药极订”网站及“药极订”移动设备客户端时，我们可能会记录您操作的相关信息以及与“药极订”服务相关的日志信息，这些信息可帮助我们向您提供精准服务并更好地保护您的账户安全。
      二、您理解并同意，“药极订”可能将用户所提供的个人信息用于以下一项或多项目的：
      1．更准确的身份确认；
      2．处理用户办理政务服务、公共服务等要求；
      3．满足用户使用各类网上服务、移动通讯服务及其他服务的要求；
      4．更好的用户服务和用户沟通，包括向用户发送相关新闻和服务信息、为用户提供有关优惠及推荐服务；
      5．预防或禁止非法的活动；
      6．经您许可的其他用途。
      三、保护和存储个人信息
      “药极订”采用了严格的安全技术和保密措施来保护数据，建立了严格的信息存储和使用制度规范，并对可能接触到您的信息的人员也采取了严格管理。“药极订”绝不会以安全为名，向您索要“药极订”账户及密码。
      四、对第三方责任的声明
      “药极订”因用户服务必要，可能会将相关网站链接至因服务必要开展合作的单位之网站（统称“第三方网站”）。本《隐私权保护规则》并不适用于这些第三方网站，因为它们不受”药极订”控制。如果您使用“药极订”提供的链接访问第三方网站，这些网站的运营商可能会收集您的个人信息。“药极订”不对这些第三方网站上的活动、隐私权政策或隐私保护水平承担任何法律或其他责任,但是“药极订”尽力要求所有链接的第三方网站采用同等的个人信息保护措施，
      第十条 法律效力
      一、用户使用“药极订”服务即视为用户已阅读本协议并接受本协议的约束。
      二、本协议所有条款的标题仅为阅读方便，本身并无实际含义，不作为本协议含义解释的依据。
      三、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      第十一条 法律适用和争议解决
      一、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不含冲突法）。
      二、您与“药极订”在履行本协议的过程中，如发生争议，应协商解决。协商不成的，均提请深圳国际仲裁院（深圳仲裁委员会）按照该会仲裁规则进行仲裁。仲裁裁决是终局性的，对双方均有约束力。
    </p>
  </div>
</div>
