import { Injectable, Injector } from '@angular/core';
import { GetSettlementInfoResponse } from '@api/models/activity/GetSettlementInfoResponse';
import { NoDataResponse } from '@api/models/activity/NoDataResponse';
import { BindWechatRequest } from '@api/models/user/BindWechatRequest';
import { WechatLoginResponse } from '@api/models/user/WechatLoginResponse';
import { WeChatRequest } from '@api/models/user/WeChatRequest';
import { BaseService } from '@geekymedic/common';
import { LoginRequest } from '../models/member/LoginRequest';
import { LoginResponse } from '../models/user/LoginResponse';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * 手机号登录
   * @param req 请求参数
   */
  mobileLogin(req: LoginRequest) {
    return this.http.post<LoginResponse>(`api/store/v1/yjdstore/login/tel_login`, req);
  }

  /**
   * 微信登录
   * @param req 请求参数
   */
  wechatLogin(req: WeChatRequest) {
    return this.http.post<WechatLoginResponse>(`api/store/v1/yjdstore/login/wechat_login`, req);
  }

  /**
   * 绑定手机号
   * @param req 请求参数
   */
  bindMobile(req: BindWechatRequest) {
    return this.http.post<LoginResponse>(`api/store/v1/yjdstore/login/bind_wechat`, req);
  }

  /**
   * 发送验证码
   * @param req 请求参数
   */
  sendVcode(req: LoginRequest) {
    return this.http.post<LoginResponse>(`api/store/v1/yjdstore/login/send_tel_login_vcode`, req);
  }
  /**
   * 微信绑定发送验证码
   */
  sendBindVcode(req: LoginRequest) {
    return this.http.post<LoginResponse>(`api/store/v1/yjdstore/login/send_wechat_bind_vcode`, req);
  }

  /**
   *  是否绑定
   */
  isBindPhone(req: BindWechatRequest) {
    return this.http.post<NoDataResponse>(`api/store/v1/yjdstore/login/check_wechat_bind`, req);
  }

  /**
   * 获取企业信息
   *
   */
  getInfo() {
    return this.http.post<GetSettlementInfoResponse>(`api/store/v1/yjdstore/supplier/get_settlement_info`, {});
  }
}
