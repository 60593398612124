/*
 * @Author: your name
 * @Date: 2020-05-21 10:40:23
 * @LastEditTime: 2020-05-21 18:06:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/api/services/staff.service.ts
 */

import { Injectable, Injector } from '@angular/core';
import { AddStaffRequest } from '@api/models/staff/AddStaffRequest';
import { AddStaffResponse } from '@api/models/staff/AddStaffResponse';
import { ChangeStaffStatusRequest } from '@api/models/staff/ChangeStaffStatusRequest';
import { ChangeStaffStatusResponse } from '@api/models/staff/ChangeStaffStatusResponse';
import { UpdateStaffRequest } from '@api/models/staff/UpdateStaffRequest';
import { UpdateStaffResponse } from '@api/models/staff/UpdateStaffResponse';
import { BaseService } from '@geekymedic/common';
import { GetStaffListResponse } from '../models/staff/GetStaffListResponse';

@Injectable({
  providedIn: 'root',
})
export class StaffService extends BaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * 获取员工列表
   */
  getStaffList() {
    return this.http.post<GetStaffListResponse>(`api/store/v1/yjdstore/staff/get_staff_list`, {});
  }

  /**
   * 新增员工
   */
  addStaff(req: AddStaffRequest) {
    return this.http.post<AddStaffResponse>(`api/store/v1/yjdstore/staff/add_staff`, req);
  }

  /**
   * 编辑员工
   */
  updateStaff(req: UpdateStaffRequest) {
    return this.http.post<UpdateStaffResponse>(`api/store/v1/yjdstore/staff/update_staff`, req);
  }

  /**
   * 更改员工状态
   */
  changeStaffStatus(req: ChangeStaffStatusRequest) {
    return this.http.post<ChangeStaffStatusResponse>(`api/store/v1/yjdstore/staff/change_staff_status`, req);
  }
}
