import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@geekymedic/common';

@Component({
  selector: 'app-service-agreement',
  templateUrl: './service-agreement.component.html',
  styleUrls: ['./service-agreement.component.less'],
})
export class ServiceAgreementComponent extends BaseComponent implements OnInit {
  constructor(injector: Injector) {
    super(injector);
  }
  expression = false;
  i: any = {
    id: 1,
  };
  ngOnInit() {}
}
