<!--
 * @Author: your name
 * @Date: 2020-05-18 14:42:40
 * @LastEditTime: 2020-05-23 11:34:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/components/search-form-collapsible/search-form-collapsible.component.html
-->
<form nz-form [formGroup]="formGroup" class="search-form-collapsible">
  <ng-content></ng-content>
  <div class="search-btns">
    <button nz-button [nzType]="'primary'" (click)="searchData()">
      <i nz-icon nzType="search"></i>搜索
    </button>
    <button type="button" nz-button [nzType]="'default'" (click)="reset()">重置</button>
    <ng-content select="extra"></ng-content>
    <a class="ml-sm text-xs" (click)="expand()" #expand>
      {{searchExpand?'收起':'展开'}}
      <i nz-icon [nzType]="searchExpand ? 'up' : 'down'"></i>
    </a>
  </div>
</form>
