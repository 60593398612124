import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@geekymedic/common';

@Component({
  selector: 'app-privacy-agreement',
  templateUrl: './privacy-agreement.component.html',
  styleUrls: ['./privacy-agreement.component.less'],
})
export class PrivacyAgreementComponent extends BaseComponent implements OnInit {
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {}
}
