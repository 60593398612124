/*
 * @Description: 文件检查服务
 * @Author: wangjiang
 * @Contact: jiang.wang@geekymedic.cn
 * @Date: 2019-10-16 10:17:28
 * @LastEditTime: 2019-11-11 10:57:41
 * @LastEditors: wangjiang
 */
import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { Observable, forkJoin, of, iif } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileCheckService {
  constructor(private message: NzMessageService) {}
  /**
   * 约束文件类型、大小、像素大小
   * @param file 文件
   * @param type 约束文件类型,为false时不约束，为true时，默认['image/jpeg','image/png','image/jpg']
   * @param size 约束文件大小，为false时不约束，为true时，默认1M
   * @param dimension 约束图片像素大小，为false时不约束，为true时，默认800*800
   */
  constraint(
    file: File,
    type?: string[] | boolean,
    size?: number | boolean,
    dimension?: [number, number] | boolean,
  ): Observable<boolean> {
    const source = [];
    if (type) {
      source.push(this.constraintType(file, type as any));
    }
    if (size) {
      source.push(this.constraintSize(file, size as any));
    }
    if (dimension) {
      source.push(this.constraintDimension(file, dimension as any));
    }
    return iif(() => source.length > 0, forkJoin(source).pipe(map(l => l.every(m => m))), of(true));
  }
  /**
   * 约束文件类型
   * @param file 文件
   * @param type 约束文件类型,默认['image/jpeg','image/png','image/jpg']
   */
  constraintType(file: File, type?: string[]): Observable<boolean> {
    if (!type || !Array.isArray(type) || type.length <= 0) {
      type = ['image/jpeg', 'image/png', 'image/jpg'];
    }
    return of(type.includes(file && file.type)).pipe(
      tap(l => !l && this.message.error(`只能上传${type.map(m => m.replace(/^\w*\//g, '')).join()}类型的文件`)),
    );
  }
  /**
   * 约束文件大小
   * @param file 文件
   * @param size 约束文件大小，默认1M
   */
  constraintSize(file: File, size?: number): Observable<boolean> {
    if (!size || typeof size !== 'number') {
      size = 1024 * 1024;
    }
    return of(file && file.size <= size).pipe(
      tap(l => !l && this.message.error(`上传文件大小不能超过 ${size / (1024 * 1024)}M`)),
    );
  }
  /**
   * 约束图片像素大小
   * @param file 文件
   * @param dimension 约束图片大小，默认800*800
   */
  constraintDimension(file: File, dimension?: [number, number]): Observable<boolean> {
    if (!dimension || !Array.isArray(dimension) || dimension.length <= 0) {
      dimension = [800, 800];
    }
    return new Observable(subscriber => {
      try {
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src!);
          subscriber.next(width === dimension[0] && height === dimension[1]);
          subscriber.complete();
        };
      } catch (error) {
        subscriber.next(false);
        subscriber.complete();
      }
    }).pipe(
      map(l => !!l),
      tap(l => !l && this.message.error(`请上传大小为 ${dimension[0]}*${dimension[1]}px的图片`)),
    );
  }
}
