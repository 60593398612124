/*
 * @Author: your name
 * @Date: 2020-05-18 10:21:49
 * @LastEditTime: 2020-05-19 15:09:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/shared-zorro.module.ts
 */

import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
export const SHARED_ZORRO_MODULES = [
  NzFormModule,
  NzGridModule,
  NzButtonModule,
  NzInputModule,
  NzInputNumberModule,
  NzAlertModule,
  NzProgressModule,
  NzSelectModule,
  NzAvatarModule,
  NzCardModule,
  NzDropDownModule,
  NzMessageModule,
  NzSpinModule,
  NzPopconfirmModule,
  NzTableModule,
  NzPaginationModule,
  NzPopoverModule,
  NzDrawerModule,
  NzModalModule,
  NzTabsModule,
  NzBadgeModule,
  NzToolTipModule,
  NzIconModule,
  NzDividerModule,
  NzSwitchModule,
  NzRadioModule,
  NzCheckboxModule,
  NzPageHeaderModule,
  NzUploadModule,
  NzCascaderModule,
  NzDatePickerModule,
];
