<!--
 * @Description: 文件注释
 * @Author: wangzhihao
 * @Contact: zhihao.wang@geekymedic.cn
 * @Date: 2019-09-01 17:25:17
 * @LastEditTime: 2020-05-18 17:37:32
 * @LastEditors: Please set LastEditors
 -->
<div>

  <nz-upload id="upload" #upload class='upload-img'
    [ngClass]="{'small': imageSize==='small','normal': imageSize==='normal','big': imageSize==='big'}"
    [nzCustomRequest]="customCreateReq" [nzName]="imageName" [(nzFileList)]="resetImglist" nzListType="picture-card"
    [nzShowButton]="resetImglist.length<imageMaxLength" [nzShowUploadList]="showUploadList" [nzPreview]="handlePreview"
    [nzRemove]="handleRemove" [nzBeforeUpload]="beforeUpload" (nzChange)="handleChange($event)">
    <i nz-icon nzType="plus"></i>
    <div class="ant-upload-text">点击上传</div>
  </nz-upload>
  <nz-modal [nzVisible]="previewVisible" [nzContent]="previewContent" [nzFooter]="null"
    (nzOnCancel)="previewVisible = false">
    <ng-template #previewContent>
      <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
    </ng-template>
  </nz-modal>
  <nz-modal [(nzVisible)]="isVisible" nzWidth="1000" [nzContent]="modalContent" [nzFooter]="null"
    (nzOnCancel)="handleCancel()">
    <ng-template #modalContent>

      <nz-spin [nzSpinning]="loading" [nzTip]="'图片上传中...'">
        <div nz-row>
          <div nz-col nzSpan="18" style="height: 400px">
            <image-cropper #cropper [imageFile]="imageFile" [maintainAspectRatio]="true" [aspectRatio]="imageRatio"
              [resizeToWidth]="imageOutputWidth" [format]="imageFormat" (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"
              outputType="file" [imageChangedEvent]="imageChangedEvent">
            </image-cropper>
          </div>
          <div nz-col nzSpan="6">
            <img [src]="croppedImageBase64" style="width:200px" />
          </div>
        </div>
        <div class="ant-modal-footer" style="padding: 10px 0 0 0">
          <div style="float: left">
            <!-- <button nz-button (click)="cropper.rotate(180)" nzType="primary">左旋转</button>
            <button nz-button (click)="cropper.rotateRight()" nzType="primary">右旋转</button>
            <button nz-button (click)="cropper.flipHorizontal()" nzType="primary">横向翻转</button>
            <button nz-button (click)="cropper.flipVertical()" nzType="primary">纵向翻转</button> -->
          </div>
          <button nz-button (click)="comfirImg()" nzType="primary">保存</button>
        </div>
      </nz-spin>
    </ng-template>
  </nz-modal>
</div>