/*
 * @Author: your name
 * @Date: 2020-05-18 10:21:49
 * @LastEditTime: 2020-05-19 15:13:33
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/shared-delon.module.ts
 */

import { EllipsisModule } from '@delon/abc/ellipsis';
import { ExceptionModule } from '@delon/abc/exception';
import { FooterToolbarModule } from '@delon/abc/footer-toolbar';
import { GlobalFooterModule } from '@delon/abc/global-footer';
import { NoticeIconModule } from '@delon/abc/notice-icon';
import { PageHeaderModule } from '@delon/abc/page-header';
import { ResultModule } from '@delon/abc/result';
import { SEModule } from '@delon/abc/se';
import { SGModule } from '@delon/abc/sg';
import { SidebarNavModule } from '@delon/abc/sidebar-nav';
import { STModule } from '@delon/abc/st';
import { SVModule } from '@delon/abc/sv';
import { G2BarModule } from '@delon/chart/bar';
import { G2CardModule } from '@delon/chart/card';
import { G2MiniBarModule } from '@delon/chart/mini-bar';
import { G2PieModule } from '@delon/chart/pie';
import { G2TimelineModule } from '@delon/chart/timeline';

export const SHARED_DELON_MODULES = [
  PageHeaderModule,
  ResultModule,
  ExceptionModule,
  NoticeIconModule,
  SidebarNavModule,
  GlobalFooterModule,
  STModule,
  SEModule,
  SVModule,
  FooterToolbarModule,
  G2BarModule,
  G2MiniBarModule,
  G2CardModule,
  G2PieModule,
  G2TimelineModule,
  SGModule,
  EllipsisModule,
];
