/*
 * @Author: your name
 * @Date: 2020-05-18 10:21:49
 * @LastEditTime: 2020-05-21 10:58:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/style-icons.ts
 */

// Custom icon static resources

import {
  ArrowLeftOutline,
  BulbOutline,
  ExceptionOutline,
  InfoOutline,
  LinkOutline,
  ProfileOutline,
  RedoOutline,
  SettingOutline,
  ShopOutline,
  UserOutline,
} from '@ant-design/icons-angular/icons';

export const ICONS = [
  InfoOutline,
  BulbOutline,
  ProfileOutline,
  ExceptionOutline,
  LinkOutline,
  ShopOutline,
  SettingOutline,
  UserOutline,
  RedoOutline,
  ArrowLeftOutline,
];
