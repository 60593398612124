<div class="container">
  <div class="header">
    <div id="login_container"></div>
  </div>
  <div class="footer">
    <!-- <span class="wechat-code">微信扫码登录更快捷安全</span> -->
    <a class="mobile-login" routerLink="/passport/mobile-login">手机验证码登录</a>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="checked" (ngModelChange)="updateSingleChecked()">确定即代表您已同意</label>
    <a class="register" (click)="goServiceAgreement()">《药极订服务协议》</a>和
    <a class="register" (click)="goPrivacyAreement()">《药极订隐私协议》</a>
  </div>
</div>
