/*
 * @Author: your name
 * @Date: 2020-05-18 14:42:40
 * @LastEditTime: 2020-05-23 11:32:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /gmt-web-yjdgys/src/app/shared/components/search-form-collapsible/search-form-collapsible.component.ts
 */

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-form-collapsible',
  templateUrl: '../search-form-collapsible/search-form-collapsible.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormCollapsibleComponent implements OnInit, AfterContentInit {
  constructor() {}

  @ViewChild('expand', { static: false }) expandRef: ElementRef<HTMLElement>;
  @Input() formGroup: FormGroup;
  @Output() searchClick = new EventEmitter();
  @Output() resetClick = new EventEmitter();
  searchExpand: boolean;

  ngOnInit() {}
  ngAfterContentInit(): void {
    setTimeout(() => {
      this.expandRef.nativeElement.setAttribute('hidden', '');
    }, 1000);
  }
  searchData() {
    this.searchClick.emit();
  }
  reset() {
    this.resetClick.emit();
  }
  expand() {
    this.searchExpand = !this.searchExpand;
    this.searchExpand ? this.expandRef.nativeElement.removeAttribute('hidden') : this.expandRef.nativeElement.setAttribute('hidden', '');
  }
}
